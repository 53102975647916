@import url('https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  display: none;
}

.simplebar-mask {
  z-index: inherit !important;
}

.simplebar-track {
  &.simplebar-vertical {
    z-index: 0;
    .simplebar-scrollbar {
      right: -3px !important;
      &::before {
        background: #141414;
        border-radius: 2px;
        width: 4px;
        opacity: 0.75;
      }
    }
  }
  &.simplebar-horizontal {
    z-index: 0;
    .simplebar-scrollbar {
      &::before {
        background: #141414;
        border-radius: 2px;
        height: 5px !important;
        opacity: 0.75;
        bottom: 0;
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.eot');
  src: url('./assets/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Bold.woff') format('woff'),
    url('./assets/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.eot');
  src: url('./assets/fonts/Roboto-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Regular.woff') format('woff'),
    url('./assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.eot');
  src: url('./assets/fonts/Roboto-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('./assets/fonts/Roboto-Medium.woff') format('woff'),
    url('./assets/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  font-family: 'DM Sans', sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}
