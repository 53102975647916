@import '../../variables.scss';

.Layout__main {
  display: flex;
  justify-content: center;
  &.alert-banner-visible {
    padding-top: 72px;
  }
  .Layout__Leftsidebar {
    flex: 0 0 360px;
    background: $dark-black-14;
    min-height: 100vh;
  }
  .Layout__Right__Sidebar {
    flex: 1 1 auto;
    overflow: hidden;
  }
}

@media (max-width: 1024px) {
  .Layout__main {
    .Layout__Leftsidebar {
      flex: 0 0 270px;
    }
  }
}

@media (max-width: 767px) {
  .Layout__main {
    flex-direction: column;
    align-items: stretch;

    &.alert-banner-visible {
      padding-top: 86px;
    }
    .Layout__Leftsidebar,
    .Layout__Right__Sidebar {
      flex: 0 0 100%;
      height: auto;
      min-height: auto;
    }
  }
}
