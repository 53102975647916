@import url(https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

body::-webkit-scrollbar {
  display: none;
}

.simplebar-mask {
  z-index: inherit !important;
}

.simplebar-track.simplebar-vertical {
  z-index: 0;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  right: -3px !important;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  background: #141414;
  border-radius: 2px;
  width: 4px;
  opacity: 0.75;
}
.simplebar-track.simplebar-horizontal {
  z-index: 0;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
  background: #141414;
  border-radius: 2px;
  height: 5px !important;
  opacity: 0.75;
  bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Bold.4a1d8c27.eot);
  src: url(/static/media/Roboto-Bold.4a1d8c27.eot?#iefix) format("embedded-opentype"), url(/static/media/Roboto-Bold.f3a02e25.woff2) format("woff2"), url(/static/media/Roboto-Bold.08cb8f79.woff) format("woff"), url(/static/media/Roboto-Bold.11d56162.svg#Roboto-Bold) format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Regular.b9077621.eot);
  src: url(/static/media/Roboto-Regular.b9077621.eot?#iefix) format("embedded-opentype"), url(/static/media/Roboto-Regular.9feb0110.woff2) format("woff2"), url(/static/media/Roboto-Regular.94dac78e.woff) format("woff"), url(/static/media/Roboto-Regular.8528a548.svg#Roboto-Regular) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url(/static/media/Roboto-Medium.02aa6e7b.eot);
  src: url(/static/media/Roboto-Medium.02aa6e7b.eot?#iefix) format("embedded-opentype"), url(/static/media/Roboto-Medium.50d01d3e.woff2) format("woff2"), url(/static/media/Roboto-Medium.9a3bf7ac.woff) format("woff"), url(/static/media/Roboto-Medium.caaae1ef.svg#Roboto-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  font-family: "DM Sans", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}
/*** Color /***/
.HeaderMobileView__main {
  display: none;
}

@media (max-width: 767px) {
  .HeaderMobileView__main {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .HeaderMobileView__main .Header__toprow {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
  }
  .HeaderMobileView__main .Header__bottomrow {
    display: flex;
    align-items: center;
  }
  .HeaderMobileView__main .Header__Userinfo__left {
    display: flex;
    align-items: center;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__ico__Sidemenu {
    cursor: pointer;
    color: #ffffff;
    background: transparent;
    cursor: pointer;
    padding: 0;
    border-radius: 2px;
    width: 34px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease-out;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__ico__Sidemenu img {
    width: 20px;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__ico__Sidemenu .ico_close {
    display: none;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__ico__Sidemenu.active .ico_menu {
    display: none;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__ico__Sidemenu.active .ico_close {
    display: block;
    width: 16px;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__UserPic {
    background: #333;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    max-width: 40px;
    max-height: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__UserPic img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  .HeaderMobileView__main .Header__Userinfo__left .Header__UserTeam__name {
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
  }
  .HeaderMobileView__main .Header__Userinfo__right {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .HeaderMobileView__main .Header__Userinfo__right .btn__upgrade {
    color: #141414;
    background: #f3c93c;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 20px;
    font-weight: bold;
  }
  .HeaderMobileView__main .Header__Userinfo__right .UserTeam__plan label {
    font-weight: 500;
    font-style: normal;
    color: #ffffff;
    color: #141414;
    background: #f3c93c;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    padding: 3px 8px;
    border-radius: 20px;
  }
}
/*** Color /***/
.NavigationLeftsideBar__main {
  background: #141414;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 360px;
  max-width: 360px;
  z-index: 1;
  top: 0;
}
.NavigationLeftsideBar__main.alert-banner-visible {
  padding-top: 72px;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 30px 22px;
  flex-wrap: wrap;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left .UserPic {
  background: #333;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  max-width: 70px;
  max-height: 70px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  line-height: 70px;
  color: #ffffff;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left .UserPic img {
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  height: 70px;
  width: 70px;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left .UserPic img.cls-default-team-logo {
  height: auto;
  width: 70%;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left .UserInfo {
  padding-left: 16px;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left .UserInfo .UserTeam__name {
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  padding-bottom: 4px;
  max-width: 230px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left .UserInfo .UserTeam__plan {
  padding-top: 5px;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__left .UserInfo .UserTeam__plan label {
  font-weight: 500;
  font-style: normal;
  color: #ffffff;
  color: #141414;
  background: #f3c93c;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  padding: 3px 8px;
  border-radius: 20px;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__right {
  padding-bottom: 10px;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__right .btn__upgrade {
  color: #141414;
  background: #f3c93c;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  padding: 4px 6px;
  border-radius: 20px;
  font-weight: bold;
}
.NavigationLeftsideBar__main .sidemenu .menulists {
  list-style: none;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem {
  padding: 15px 27px;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem.active {
  background: #ffffff;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem.active a {
  color: #0b1d39;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem a {
  color: #ffffff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 4px 0;
  transform: translateZ(0);
  transition: opacity 0.3s ease-in-out;
  font-weight: 500;
  font-style: normal;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem a .menu-icon {
  transform: translateZ(0);
  transition: transform 0.2s ease-out;
  height: 18px;
  width: 24px;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem a .menu-icon.open-web-app {
  transform: rotate(-90deg);
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem a:hover .menu-icon {
  transform: translateX(4px);
  height: 18px;
  width: 24px;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem a:hover .menu-icon.open-web-app {
  transform: translateX(4px) rotate(-90deg);
  height: 18px;
  width: 24px;
}
.NavigationLeftsideBar__main .sidemenu .menulists .menuitem a span {
  vertical-align: text-top;
  padding-left: 25px;
  font-size: 18px;
  line-height: 20px;
  text-transform: capitalize;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__logo {
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  background: #141414;
}
.NavigationLeftsideBar__main .NavigationLeftsideBar__logo img {
  max-width: 128px;
  width: 100%;
}
.NavigationLeftsideBar__main .disableMenuLists {
  pointer-events: none;
}

@media (max-width: 1024px) {
  .NavigationLeftsideBar__main {
    flex: 0 0 270px;
    width: 270px;
    max-width: 270px;
  }
  .NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail {
    padding: 15px 20px;
  }
  .NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail .Userinfo__right {
    padding: 10px 0px 0;
    text-align: right;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .Layout__Leftsidebar {
    position: relative;
  }
  .Layout__Leftsidebar .HeaderMobileView__main {
    z-index: 99;
    position: relative;
    background: #141414;
  }
  .Layout__Leftsidebar .NavigationLeftsideBar__main {
    padding: 0;
    width: 100%;
    max-width: 100%;
    position: absolute;
    height: auto;
    transition: all 0.6s ease-in-out;
    transform: translateY(-100%);
    top: 100%;
    z-index: 20;
    opacity: 0;
  }
  .Layout__Leftsidebar .NavigationLeftsideBar__main .NavigationLeftsideBar__logo img {
    width: 80px;
  }
  .Layout__Leftsidebar .NavigationLeftsideBar__main.mobfull__sidebar {
    transform: translateY(0);
    opacity: 1;
  }
  .Layout__Leftsidebar .NavigationLeftsideBar__main .NavigationLeftsideBar__UserDetail {
    display: none;
  }
}
/*** Color /***/
.AlertBanner_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 22px 16px;
  background-color: #f3c93c;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 100;
}
.AlertBanner_container.hidden {
  display: none;
}

.AlertBanner_message {
  margin: auto;
}

.AlertBanner_message h1 {
  font-size: 16px;
  font-weight: 500;
  color: #141414;
}

.AlertBanner_message h1 span {
  font-weight: bold;
}

.AlertBanner_message h1 a {
  text-decoration: underline;
  font-weight: bold;
  color: #141414;
}

.AlertBanner_close {
  background: none;
  border: none;
  cursor: pointer;
}

.AlertBanner_close svg path {
  transition: ease-in-out 0.25s;
}

.AlertBanner_close:hover svg path {
  opacity: 1;
  transition: ease-in-out 0.25s;
}
/*** Color /***/
.Layout__main {
  display: flex;
  justify-content: center;
}
.Layout__main.alert-banner-visible {
  padding-top: 72px;
}
.Layout__main .Layout__Leftsidebar {
  flex: 0 0 360px;
  background: #141414;
  min-height: 100vh;
}
.Layout__main .Layout__Right__Sidebar {
  flex: 1 1 auto;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .Layout__main .Layout__Leftsidebar {
    flex: 0 0 270px;
  }
}
@media (max-width: 767px) {
  .Layout__main {
    flex-direction: column;
    align-items: stretch;
  }
  .Layout__main.alert-banner-visible {
    padding-top: 86px;
  }
  .Layout__main .Layout__Leftsidebar,
  .Layout__main .Layout__Right__Sidebar {
    flex: 0 0 100%;
    height: auto;
    min-height: auto;
  }
}
.custom-loader {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding-right: 30px;
}
.custom-loader.hide {
  display: none;
}
